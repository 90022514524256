import PropTypes from "prop-types"
import React, { Component } from 'react'
import classnames from 'classnames';
import { animateScroll as scroll } from 'react-scroll';
import anime from 'animejs'
import logo from '../../static/images/logo.svg';
import open from '../../static/images/open-white.svg';
import closed from '../../static/images/close-black.svg';
import Hamburger from "../Hamburger";
import Navigation from "../Navigation";

class Header extends Component {

  static propTypes = {
    siteTitle: PropTypes.string,
  }

  static defaultProps = {
    siteTitle: ``,
  }

  state = {
    navVisible: true,
  }

  toggleNavigation = () => {
    this.setState(prevState => ({ navVisible: !prevState.navVisible }));
  }

  onBackToTop() {
    return scroll.scrollToTop();
  }

  get screenSize() {
    return window.innerWidth;
  }
  
  onShowMenu() {
    anime({
      targets: '.navigation-circle__container',
      rotate: -180,
      easing: 'easeInOutExpo',
      begin: function() {
        document.querySelector('.navigation-circle__container').style.display = 'flex';
      },
    });
    anime({
      targets: '.navigation-items',
      translateX: [this.screenSize, 0],
      easing: 'easeInOutExpo',
      delay: anime.stagger(100),
    });
    anime({
      targets: '.navigation-item',
      translateX: [this.screenSize, 0],
      easing: 'easeInOutExpo',
      delay: anime.stagger(150),
    });
  }

  onHideMenu() {
    anime({
      targets: '.navigation-items',
      translateX: this.screenSize,
      easing: 'easeInOutExpo',
      delay: anime.stagger(-100),
    })
    anime({
      targets: '.navigation-item',
      translateX: this.screenSize,
      easing: 'easeInOutExpo',
      delay: anime.stagger(-150),
    })
    anime({
      targets: '.navigation-circle__container',
      rotate: 0,
      easing: 'easeInOutExpo',
      delay: 20,
      begin: function() {
        document.querySelector('.navigation-circle__container').style.display = 'flex';
      },
      complete: function() {
        document.querySelector('.navigation-circle__container').style.display = 'none';
      },
    })
  }

  render() {
    const { siteTitle } = this.props;
    const { navVisible } = this.state;
    return (
      <>
        <div className="custom-padding" style={{position: "fixed", width: '100%', paddingTop: '30px', zIndex: 1000}}>
          <div className="col-12">
            <img src={logo} alt="logo" style={{height: '32px'}}/>
          </div>
        </div>
      {/*<Navigation onClick={() => {*/}
      {/*  this.toggleNavigation();*/}
      {/*  this.onHideMenu();*/}
      {/*  this.onBackToTop();*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<div*/}
      {/*  className={classnames(*/}
      {/*    'header logo-text headline headline-tall headline-regular',*/}
      {/*    {['header--active']: !navVisible}*/}
      {/*  )}*/}
      {/*>*/}
      {/*  {siteTitle}*/}
      {/*</div>*/}
      {/*<header className="page-header custom-padding">*/}
      {/*  <div className="page-header-content-wrapper">      */}
      {/*    <div className="logo">*/}
      {/*      <div className="logo-image" >*/}
      {/*        <img src={logo} alt="logo"  style={{paddingTop: '30px'}}/>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div>*/}
            {/*<Hamburger*/}
            {/*  onClick={() => {*/}
            {/*    this.toggleNavigation();*/}
            {/*    navVisible ? this.onShowMenu() : this.onHideMenu();*/}
            {/*  }}*/}
            {/*  style={{background: navVisible ? `url(${open})` : `url(${closed})`}}*/}
            {/*/>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</header>*/}
      </>
    )
  }
}

export default Header
