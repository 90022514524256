import React from 'react';
import classNames from 'classnames';
import styles from './HeroSection.module.scss';
import Anime from "../../animations/Anime";
import ScrollAnime from "../../animations/ScrollAnime";
import {getRelativePosition} from "../../../helpers/position";
import {getWindowHeight} from "../../../helpers/window";


class HeroSection extends React.PureComponent {
    callbackForScrollAnime = (scrollAnime, sectionRef) => {
        const { current } = sectionRef;
        scrollAnime.scene.offset(getRelativePosition(current))
        const a = document.getElementById('heroSection_discover');
        const pozycjakoncowa = getRelativePosition(document.getElementById('processInfo_discover'));
        const duration = pozycjakoncowa - getWindowHeight() + a.getBoundingClientRect().height;
        scrollAnime.scene.duration(duration);
    }

    callbackForAnime = (processId, heroId) => {
        return {
            translateY: [
                '0px',
                getRelativePosition(document.getElementById(processId))
                - getRelativePosition(document.getElementById(heroId))
            ]
        }
    }

    render() {
        const sectionRef = React.createRef();
        const windowHeight = getWindowHeight();
        return (
            <section ref={sectionRef} name="hero" className={classNames('section container-fluid', styles.heroSection)}>
                <div className="d-flex align-items-center section">
                    <div className="row">
                        <header className="col-xs-4 col-lg-2 order-xs-2 order-lg-1">
                            <h1 className={classNames('headline', styles.heroSection__headline)}>
                                <ScrollAnime
                                    name="Discover"
                                    duration={800}
                                    offsetCallback={(scrollAnime) => this.callbackForScrollAnime(scrollAnime, sectionRef)}
                                >
                                    <Anime
                                        callbackMount={() => this.callbackForAnime('processInfo_discover', 'heroSection_discover')}
                                    >
                                        <div id="heroSection_discover">Discover.</div>
                                    </Anime>
                                </ScrollAnime>
                                <ScrollAnime
                                    name="Discover"
                                    duration={800}
                                    offsetCallback={(scrollAnime) => this.callbackForScrollAnime(scrollAnime, sectionRef)}
                                >
                                    <Anime
                                        callbackMount={() => this.callbackForAnime('processInfo_design', 'heroSection_design')}
                                    >
                                        <div id="heroSection_design">Design.</div>
                                    </Anime>
                                </ScrollAnime>
                                <ScrollAnime
                                    name="Discover"
                                    duration={800}
                                    offsetCallback={(scrollAnime) => this.callbackForScrollAnime(scrollAnime, sectionRef)}
                                >
                                    <Anime
                                        callbackMount={() => this.callbackForAnime('processInfo_deliver', 'heroSection_deliver')}
                                    >
                                        <div id="heroSection_deliver">Deliver.</div>
                                    </Anime>
                                </ScrollAnime>
                            </h1>
                        </header>

                        <ScrollAnime
                            name="CSS"
                            offsetCallback={(scrollAnime) => this.callbackForScrollAnime(scrollAnime, sectionRef)}
                            duration={windowHeight}
                        >
                            <Anime
                                opacity={[1, 0]}
                                easing='easeOutCirc'
                            >
                                <div className="col-xs-4 col-lg-2 order-xs-1 order-lg-2">
                                    <p className={styles.text}>
                                        We help companies uncover true needs, design the experience and build the
                                        solution.
                                    </p>
                                    <div className={styles.heroSection__btnGroup}>
                                        <a className="btn btn--primary">Start your project</a>
                                        <a className="btn btn--link">Learn more</a>
                                    </div>
                                </div>
                            </Anime>
                        </ScrollAnime>
                    </div>
                </div>
                <div className={styles.margin}/>
            </section>
        );
    }
}

export default HeroSection;