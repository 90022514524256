import React, {PureComponent} from 'react';
import classNames from 'classnames';
import styles from './ContactSection.module.scss';
import ContactForm from "../../ContactForm";

class ContactSection extends React.PureComponent {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            status: ""
        };
    }

    submitForm = (ev) => {
        ev.preventDefault();

        const form = ev.target;
        // const data = new FormData(form);
        const data = new FormData();
        data.append('email', 'adam@moonite.co');
        data.append('name', 'adam');
        data.append('description', 'test description');

        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                this.setState({status: "SUCCESS"});
            } else {
                this.setState({status: "ERROR"});
            }
        };
        xhr.send(data);
    }

    render() {
        return (
            <section className={classNames('section container-fluid d-flex align-items-center', styles.contactSection)}>
                <div className="row">
                    <div className="col-4">
                        <header className={styles.headline}>
                            <h2>Start your project</h2>
                        </header>
                    </div>
                    <div className="col-4">
                        <ContactForm submitForm={this.submitForm} status={this.state.status}/>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactSection;
