import React from "react";
import PropTypes from "prop-types";
import ScrollMagic from "scrollmagic";
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import Anime from "./Anime";
import ScrollMagicSingleton from "../../static/ScrollMagicSingleton";
import {MOONITE_SITE_READY_EVENT} from "../constants";


const PREFIX = '__scroll__';

export default class ScrollAnime extends React.PureComponent {
    static defaultProps = {
        offset: 0,
        duration: 0,
    }

    static propTypes = {
        offsetCallback: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.targets = [];
        this.targetRefs = [];
        this.scene = null;

        if (typeof this.props.offsetCallback === "function" && typeof window !== `undefined`) {
            window.addEventListener(MOONITE_SITE_READY_EVENT, this.handlePosition)
        }
    }

    handlePosition = () => {
        this.props.offsetCallback(this);
    }

    componentDidMount() {
        this.scrollMagicController = (new ScrollMagicSingleton()).getScroll();
        this.createScroll();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.scene.destroy();
        this.createScroll();
    }

    createScroll = () => {
        this.scene = new ScrollMagic.Scene({
            offset: this.props.offset,
            duration: this.props.duration
        })
            .addTo(this.scrollMagicController)
            .on('progress', ({progress}) => {
                this.targetRefs.forEach((target) => {
                    target.current.anime.seek(target.current.anime.duration * progress);
                })
            })
        ;

        if(typeof this.props.onEnter === "function") {
            this.scene.on('enter', (e) => this.props.onEnter(e));
        }

        if(typeof this.props.onLeave === "function") {
            this.scene.on('leave', (e) => this.props.onLeave(e));
        }

        if (process.env.NODE_ENV === 'development') {
            this.scene.addIndicators({name: this.props.name})
        }
    }

    render() {
        let children = this.props.children;
        this.targetRefs = [];
        let refs = this.targetRefs;
        if (!Array.isArray(children)) children = [children];
        return (
            <React.Fragment>
                {children.map((child, i) => {
                    if (child.type !== Anime && !(child.type.prototype instanceof Anime)) {
                        return React.cloneElement(child, {
                            key: PREFIX + i,
                        });
                    }
                    refs.push(React.createRef());
                    return React.cloneElement(child, {
                        ref: refs[refs.length - 1],
                        key: PREFIX + i,
                        autoplay: false,
                    });
                })}
            </React.Fragment>
        );
    }
}
