import React from "react";
import TextField, {Input} from '@material/react-text-field';

const ContactForm = ({submitForm, data, onChange}) => (
    <form name="contact"
          onSubmit={submitForm}
          action="https://formspree.io/mvowkklv"
          method="POST">
        {/* <TextField
            label="What’s your name?"
        >
            <Input
                value={data.name}
                onChange={(e) => onChange(e, 'name')}
            />
        </TextField> */}
        <TextField
            label="Email address"
        >
            <Input
                value={data.mail}
                onChange={(e) => onChange(e, 'mail')}
            />
        </TextField>
        {/* <TextField
            label="Describe the project briefly…"
        >
            <Input
                value={data.description}
                onChange={(e) => onChange(e, 'description')}
            />
        </TextField> */}
        {data.status === "SUCCESS"
        ? <button className="cta-button" style={{width: '100%', marginTop: '2rem'}} className="btn btn-submit">Talk to you soon!</button>
        : <button className="cta-button" style={{width: '100%', marginTop: '2rem'}} className="btn btn-submit">Book your call</button>}
        <p>
            {data.status === "ERROR" && 'Ooops! There was an error.'}
            {data.status === "SUCCESS" && `We'll send you meeting time proposal within 48h.`}
        </p>
        <p><br/></p>
        <small>Afraid of forms? Drop us a line at <strong>hello[at]moonite.co</strong>.</small>

    </form>
);

export default ContactForm;