let instance = null;

export default class ScrollMagicSingleton {
    constructor() {
        if (instance) {
            return instance;
        }
        instance = this;
        this.scrollMagic = null;
    }

    getScroll() {
        return this.scrollMagic;
    }

    setScroll(scrollMagic) {
        this.scrollMagic = scrollMagic;
    }

}