import React from "react";
import {getRelativePosition} from "../../../../helpers/position";
import Anime from "../../../animations/Anime";
import ScrollAnime from "../../../animations/ScrollAnime";

class ProcessInfoAnime extends React.Component {
    render() {
        const ref = React.createRef();
        return (
            <ScrollAnime
                name={this.props.name}
                offsetCallback={function (scrollAnime) {
                    const {current} = ref;
                    scrollAnime.scene.offset(
                        getRelativePosition(current)
                        - (window.innerHeight - (window.innerHeight * 0.125 + 86))
                    );
                    scrollAnime.scene.duration(window.innerHeight * 0.125);
                }}
            >
                <Anime
                    opacity={[0, 1]}
                >
                    {
                        React.cloneElement(this.props.children, {
                            _ref: ref,
                        })
                    }
                </Anime>
            </ScrollAnime>
        )
    }
}

export default ProcessInfoAnime