import React from "react";
import styles from "./LocationSection.module.scss";
import anime from "animejs";
import ScrollAnime from "../../animations/ScrollAnime";

class LocationMarker extends React.PureComponent {
    constructor(props) {
        super(props);

        this.lineRef = React.createRef();
        this.circleRef = React.createRef();
    }

    componentDidMount() {
        this.timeline = anime.timeline({
            autoplay: false,
            duration: this.props.animationDuration,
        });

        this.timeline.add({
            targets: this.circleRef.current,
            scale: [0, 1],
            easing: 'easeOutBounce',
        }).add({
            targets: this.lineRef.current,
            height: [0, '30vh'],
            easing: 'linear',
        });
    }

    onEnter = () => {
        if (this.timeline.direction !== 'normal') {
            this.timeline.reverse();
        }
        this.timeline.play();
    };

    onLeave = () => {
        this.timeline.reverse();
        this.timeline.play();
    };

    render() {
        return (
            <React.Fragment>
                <ScrollAnime
                    name="location_marker_pull_down"
                    offsetCallback={this.props.offsetCallback}
                    onEnter={this.onEnter}
                    onLeave={this.onLeave}
                >
                    <div className={styles.locationMarker__container}>
                        <div className={styles.marker__line} ref={this.lineRef}/>
                        <div className={styles.marker__circle} ref={this.circleRef}/>
                    </div>
                </ScrollAnime>
            </React.Fragment>
        );
    }
}

export default LocationMarker;
