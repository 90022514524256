import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ProcessArticle.module.scss';

class ProcessInfo extends React.Component {
    render() {
        return (
            <article className={styles.processArticle} ref={this.props._ref}>
                <header>
                    <div id={this.props.id} className={classNames('headline opacity-0', styles.headlineLarge)}>{this.props.name}</div>
                    <h3 className={classNames('headline', styles.processArticle__headline)}>{this.props.title}</h3>
                </header>
                <p className="text--bold m-0">{this.props.description}</p>
            </article>
        )
    }
}

ProcessInfo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
}

export default ProcessInfo;