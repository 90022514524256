import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-scroll'


const items = [
  {
    label: 'Home',
    linkTo: 'hero',
  },
  {
    label: 'Our work',
    linkTo: 'work',
  },
  {
    label: 'Team',
    linkTo: 'team',
  },
  {
    label: 'Contact',
    linkTo: 'contact',
  },
];

class Navigation extends Component {

  static propTypes = {
    onClick: PropTypes.func,
  }

  render() {
    const { onClick } = this.props;
    return (
      <>
        <div className="navigation-circle__container">
          <div className="navigation-circle__small"/>
          <div className="navigation-circle__big"/>
        </div>
        <div className="navigation-items">
          {items.map((item) =>
            <Link to={item.linkTo} onClick={() => onClick()} spy smooth duration={800} delay={200}>
              <span className="navigation-item">{item.label}</span>
            </Link>
          )}
        </div>
      </>
    )
  }
}

export default Navigation;
