export function getRelativePosition(element) {
    let bodyRect = document.body.getBoundingClientRect();
    let elemRect = element.getBoundingClientRect();
    return elemRect.top - bodyRect.top;
}

export function getRelativePositionByElement(element1, element2) {
    let positionElement1 = element1.getBoundingClientRect().top;
    let positionElement2 = element2.getBoundingClientRect().top;
    return Math.abs(positionElement1 - positionElement2);
}