import React from "react";
import { Link } from 'react-scroll';
import Layout from "../components/layout/Layout";
import Seo from "../components/Seo";
import ContactSection from "../components/sections/ContactSection";
import HeroSection from "../components/sections/HeroSection";
import ProcessSection from "../components/sections/ProcessSection";
import ReviewsSection from "../components/sections/ReviewsSection";
import LocationSection from "../components/sections/LocationSection";
import ContactForm from "../components/ContactForm";
import Header from "../components/layout/Header";
import herobg from "../static/images/hero-bg-bottom_j80.jpg";
import herotop from "../static/images/hero-sphere-top.png";
import footerbg from "../static/images/footer-ground-bg.png";
import back_gradient from "../static/images/transparent-black-gradient.png";
import moon from '../static/images/moon.png';
import { Helmet } from "react-helmet";

class IndexPage extends React.PureComponent {
    counter = 0;
    length = 0;

    static initialState = {
        name: '',
        mail: '',
        description: '',
        status: '',
        send: false
    }

    constructor(props) {
        super(props);
        // if(typeof window !== `undefined`) {
        //     const scrollMagic = new ScrollMagicSingleton();
        //     scrollMagic.setScroll(new ScrollMagic.Controller({
        //         globalSceneOptions: {
        //             triggerHook: 0
        //         }
        //     }));
        // }
        this.submitForm = this.submitForm.bind(this);
        this.state = IndexPage.initialState;
    }

    componentDidMount() {
        // const imgs = document.images;
        // this.length = imgs.length;
        //
        // const incrementCounter = this.incrementCounter;
        // [].forEach.call(imgs, function (img) {
        //     if (img.complete)
        //         incrementCounter();
        //     else
        //         img.addEventListener('load', () => {
        //             incrementCounter()
        //         }, false);
        // });

        // setTimeout(() => {
        //     const event = new Event(MOONITE_SITE_READY_EVENT);
        //     window.dispatchEvent(event);
        // }, 2000)
    }

    // incrementCounter = () => {
    //     this.counter++;
    //     if (this.counter === this.length) {
    //         const event = new Event(MOONITE_SITE_READY_EVENT);
    //             window.dispatchEvent(event);
    //     }
    // }


    submitForm = (ev) => {
        ev.preventDefault();
        if (this.state.send || !this.state.mail) {
            return;
        }
        const form = ev.target;
        // const data = new FormData(form);
        const data = new FormData();
        data.append('email', this.state.mail);

        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                this.setState({...IndexPage.initialState,  status: "SUCCESS", send: true });
            } else {
                this.setState({ status: "ERROR" });
            }
        };
        xhr.send(data);
    }

    render() {
        return (
            <>
            <Helmet>
                <title>moonite</title>
            </Helmet>
            <div className="hero-background">
                <Header siteTitle={'moonite'} />
                <div className="container-fluid">
                    <div className="row custom-padding" style={{
                        minHeight: '100vh',
                        alignItems: 'center',
                        paddingTop: '60px'
                    }}>
                        <div className="col-12 col-lg-8 col-xl-6">
                            <h1 className="font2 process-title hero-head">
                                <div className="process-title">Discover.</div>
                                <div className="process-title">Design.</div>
                                <div className="process-title">Deliver.</div>
                            </h1>
                        </div>
                        <div className="col-12 col-lg-4 col-xl-6">
                            <div className="row" style={{ height: '100%', alignContent: 'center', justifyContent: "center" }}>
                                {/*<div className="col-12 offset-lg-1 col-lg-10">*/}
                                {/*    <div className="row">*/}
                                <div className="hero-info">
                                    <div className="col-12 font-weight-bold hero-info-desc">
                                        We help companies uncover true needs, design the experience and build the
                                        solution.
                                        </div>
                                    <div className="col-12">
                                        <Link to="contact" spy={true} smooth={true} duration={1500} delay={200}>
                                            <button
                                                style={{
                                                    margin: '0px',
                                                    marginBottom: "2.639vh"
                                                }}
                                                onClick={() => {}}
                                                className="btn btn--primary cta-button"
                                            >
                                                Discover your needs
                                            </button>
                                         </Link>
                                         <Link to="process" spy={true} smooth={true} duration={1500} delay={200} offset={-130}>
                                            <button
                                                style={{margin: '0px'}}
                                                onClick={() => {}}
                                                className="btn btn--link"
                                            >
                                                Check our process
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid process-background">
                    <div className="row">
                        <div className="w-100 p-our" style={{
                            textAlign: "right", justifyContent: "flex-end", display: "flex", overflow: "hidden",

                        }}>
                            <div
                                name="process"
                                className="font2 font-weight-extra-bold" style={{
                                textAlign: "left",
                                textTransform: "uppercase",
                                transform: 'translate(30px, 0px)',
                            }}>
                                <span className="d-block process-head-first">Our</span>
                                <span className="d-lg-none d-block process-head-second">pro</span>
                                <span className="d-lg-none d-block process-head-second">cess</span>
                                <span className="d-none d-lg-block process-head-second">process</span>
                            </div>
                        </div>
                    </div>
                    <div className="row custom-padding" style={{ minHeight: '100vh', paddingBottom: '120px' }}>
                        <div className="col-12 process-block">
                            <h2 className="process-title font2">Discover.</h2>
                            <span className="process-w d-block font2">true needs</span>
                            <span className="process-description font-weight-bold">Most of the people talk solutions – we talk needs. We support it with data.</span>
                        </div>
                        <div className="col-12 process-block">
                            <h2 className="process-title font2">Design.</h2>
                            <span className="process-w d-block font2">the experience</span>
                            <span className="process-description font-weight-bold">Great one comes from combining creativity with finding the path to users’ success.</span>
                        </div>
                        <div className="col-12 process-block">
                            <h2 className="process-title font2">Deliver.</h2>
                            <span className="process-w d-block font2">the solution</span>
                            <span className="process-description font-weight-bold">All the efforts goes to transfer the experience into the working solution. It all becomes real.</span>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" style={{ background: "#000"}}>
                    <div className="row" style={{ position: "relative", overflow: "hidden" }}>
                        <div className="custom-padding" style={{zIndex: 1}}>
                            <div className="col-12 location" style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: '100px'
                            }}>
                                <span className="font2 font-weight-extra-bold ">we're from</span>
                                <span className="font2 font-weight-extra-bold">Earth</span>
                                <span className="font-weight-bold">Located in Poland.</span>
                                <span className="font-weight-bold">Working remotely.</span>
                                <span className="font-weight-bold">Available around the World.</span>
                                <div className="marker__circle"></div>
                            </div>
                        </div>
                        <div style={{width: '100vw', position: "relative"}}>
                            <div className="earth-planet">
                                <img alt="moon" src={moon} />
                            </div>
                        </div>

                        <div className="earth-background" style={{
                            backgroundImage: "url(" + footerbg + ")",

                        }}>
                        </div>
                        <div name="contact" className="contact-section custom-padding">
                            <div className="col-12" style={{paddingTop: '5rem', paddingBottom: '5rem'}}>
                                <header className="headline">
                                    <h2>Let's discover your needs, together.</h2>
                                    <p>Book a free 20 minute call with us to find out your best next step.</p>
                                </header>
                                <ContactForm
                                    submitForm={this.submitForm}
                                    data={this.state}
                                    onChange={(e, name) => {this.setState({[name]: e.target.value})}}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="footer">
                                <div>Oh! By the way, we did this site.</div>
                                <div className="text--bold">{new Date().getFullYear()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            // <Layout>
            // <Seo title="Home"/>
            // <Header siteTitle={'moonite'}/>
            // <HeroSection/>
            // <ProcessSection/>
            // <ReviewsSection/>
            // {/*<GalaxySection>*/}
            // {/*    <WorkSection/>*/}
            // {/*    <TeamSection/>*/}
            // {/*</GalaxySection>*/}
            // <LocationSection/>
            // <ContactSection/>
            // </Layout>
        )
    }
}

export default IndexPage;
