import React, {memo} from "react";
import classNames from 'classnames';
import styles from './LocationSection.module.scss';
import earth from './../../../static/images/earth.png';
import moon from '../../../static/images/moon.png';
import Anime from "../../animations/Anime";
import ScrollAnime from "../../animations/ScrollAnime";
import {getRelativePosition} from "../../../helpers/position";
import LocationMarker from "./LocationMarker";


const LocationSection = () => {
    const sectionRef = React.createRef();
    const moonSize = {
        width: 547,
        height: 548,
    };
    return (
        <section className={classNames('section container-fluid', styles.locationSection)} ref={sectionRef}>
            <div className={classNames('row', styles.locationSection__row)}>
                <div className="col-4">
                    <header>
                        <h2 className={classNames('headline', styles.locationSection__headline)}>
                            <div className={classNames('headline-bold', styles.headlineSmall)}>we're from</div>
                            <div className={classNames('headline-extra-bold', styles.headlineLarge)}>Earth</div>
                        </h2>
                    </header>
                </div>
                <div className="col-4">
                    <p>
                        Located in Poland.<br/>
                        Working remotely.<br/>
                        Available around the World.
                    </p>
                </div>
            </div>
            <div className={classNames('row d-flex', styles.locationSection__row)}>
                <div className="col-2">
                    <LocationMarker
                        offsetCallback={({scene}) => {
                            const {current} = sectionRef;
                            scene.offset(getRelativePosition(current) * 0.96);
                            scene.duration(current.getBoundingClientRect().height);
                        }}
                        animationDuration={300}
                    />
                </div>
            </div>
            <ScrollAnime
                name="location_earth"
                offsetCallback={({scene}) => {
                    const {current} = sectionRef;
                    scene.offset(getRelativePosition(current) - window.innerHeight);
                    scene.duration(getRelativePosition(current) - (getRelativePosition(current) - window.innerHeight));
                }}
            >
                <Anime
                    translateY={['100%', '0%']}
                    easing='linear'
                    style={{position: 'absolute', bottom: 0, left: 0, zIndex: 1200}}
                >
                    <img className="d-block" src={earth} alt="earth"/>
                </Anime>
            </ScrollAnime>
            <ScrollAnime
                name="location_moon"
                duration={1000}
                offsetCallback={({scene}) => {
                    const {current} = sectionRef;
                    scene.offset(getRelativePosition(current) - 100);
                }}
            >
                <Anime
                    translateY={['10%', '50%']}
                    translateX={['100vw', -moonSize.width]}
                    easing='linear'
                    duration={1000}
                    style={{position: 'absolute', top: 0, left: 0, zIndex: 1100}}
                >
                    <img className={styles.moon} src={moon} alt="moon"/>
                </Anime>
            </ScrollAnime>
        </section>
    );
}

export default memo(LocationSection);
